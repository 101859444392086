export const environment = {
  production: false,
  hmr: false,
  API_URL: 'https://deudekom.acceptatie.emendis.nl/api',
  BASE_URL: 'https://deudekom.acceptatie.emendis.nl',
  sentryUrl: 'https://2cda28df4e532ac4f8f138971ae1ce47@o4504360297496576.ingest.us.sentry.io/4507333590515712',
  envName: 'acceptance',
  messageTimeout: 5000,
  dev: false
};
